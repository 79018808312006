<template>
    <div id="memberIndexPage">
      <div class="top-nav-bar">
        <van-nav-bar :title="$route.meta.title" @click-left="$router.go(-1)">
          <van-icon class="back-arrow" name="arrow-left"  slot="left" />
          <div class="right" slot="right">
            <router-link v-show="false" :to="{name:'MemberOrderList'}" tag="button" class="btn" type="button" v-if="userInfo.isBuyRecord">購入履歴</router-link>
          </div>
        </van-nav-bar>
      </div>

      <div class="container">
        <div class="section section-1" v-if="userInfo.whether">
          <div class="section-title">
            <van-image
              width="100%"
              :src="personInfo.photoUrl"
              class="img"
              fit="contain"
            />
            <div class="title">
              <div class="name"  v-if="userInfo.state===2">{{ $utils.formatDate(userInfo.createTime , 'yyyy-MM-dd') }}から{{ userInfo.name }}になりました！</div>
              <div class="name" v-if="userInfo.state===3">{{ $utils.formatDate(userInfo.createTime , 'yyyy-MM-dd') }}に{{ userInfo.name }}から{{ userInfo.nextName }}に変更します</div>
            </div>
            <!-- 先判断存不存在join方法 -->
            <div class="tip" v-if="userInfo.jurisdiction">{{ userInfo.jurisdiction.join(';') }}</div>
          </div>
        </div>
        <!-- 没有订阅会员展示头部信息开始 -->
        <div class="header" v-else>
          <img class="bg" :src="imgObj[0]" draggable="false">
          <div class="header-content" v-if="!selected">
            <div class="title">ようこそVRAVOへ</div>
            <div class="tip">
              <span class="name">サブスクリプションに加入して、新しいVR世界へ</span>
            </div>
          </div>
        </div>
        <!-- 没有订阅会员展示头部信息结束 -->

        <div class="section section-2">
          <div class="section-title">
            <div class="title">
              <div class="name" v-if="!userInfo.whether">3つの選べるプラン</div>
              <div class="name" v-else>2つの選べるプラン</div>
            </div>
          </div>
          <!-- 三种礼包展示开始 -->
          <div class="section-content" v-if="getLift">
            <ul class="list">
              <li
                class="list-item"
                :class="{active: selectIndex === index , [`list-item-${item.id}`]: true}"
                :key="index"
                @click="onSelect(index , item.id)"
                v-for="(item, index) in getLift"
              >
                <div class="left">
                  <div class="top">
                    <div class="title">{{ item.gradesName }}</div>
                    <div class="desrc">{{ item.interestsName.join(';') }}</div>
                  </div>
                  <div class="bottom">
                    <span class="price">
                      <span class="big">{{item.sellingPrice}}</span>円
                    </span>
                    <span class="del">{{item.originalCost}}円</span>
                  </div>
                </div>
                <div class="right">
                  <i class="icon icon-checked"></i>
                </div>
              </li>
            </ul>
          </div>
          <!-- 三种礼包展示结束 -->

          <!-- 有订阅会员时展示开始 -->
          <div class="section-bottom" v-if="$utils.getToken() && userInfo.isCallUnsubscribe!==null && !userInfo.isCallUnsubscribe">
            <button @click="popOpen('visible4')" type="button" class="btn">サブスクをキャンセル</button>
          </div>
          <!-- 有订阅会员时展示结束 -->

        </div>
      </div>
      <div class="footer">
        <!-- <div class="tip-box">
          <p>サブスクリプション規約とプライバシ</p>
          <p>ーポリシーに同意は必要です</p>
        </div> -->
        <div class="nav">
          <van-checkbox v-model="agreementChecked" ></van-checkbox>
          <span :style="{color: agreementChecked ? '#fff': ''}">
            <router-link
              class="name"
              :to="{name: 'Agreement', params: { type: 3}}"
            >サブスクリプション規約</router-link>
            <span>と</span>
            <router-link
              class="name"
              :to="{name: 'Agreement', params: { type: 1 }}"
            >プライバシーポリシー</router-link>
            <span>に同意</span>
          </span>
        </div>
        <div @click="onSubmit" class="submit-btn" v-if="!userInfo.whether">
          <span>すぐ購入</span>
        </div>
         <div @click="onChange" class="submit-btn" v-else>
          <span>会員ランク変更</span>
        </div>
      </div>
      <van-popup
        class="popup popup-bindPay"
        v-model="visible1"
      >
        <div class="content">调用第三方接口：填写支付账号信息</div>
      </van-popup>
      <van-popup
        position="bottom"
        class="popup popup-changeType"
        v-model="visible2"
      >
        <div class="content">
          <div class="popup-title">選択肢をお選びください</div>
          <ul class="list">
            <li class="list-item" @click="now('1')">すぐに変更</li>
            <li class="list-item" @click="now('2')">サービス期間終了後自動変更</li>
          </ul>
          <div class="tip"><button type="button" @click="popOpen('visible3')" class="name">詳しく</button></div>
          <button type="button" @click="popClose('visible2')" class="submit-btn">キャンセル</button>
        </div>
      </van-popup>
      <van-popup
        class="popup popup-changeTip"
        closeable
        v-model="visible3"
      >
        <div class="content">
          <div class="popup-title">詳しく</div>
          <ul class="list">
            <li class="list-item">
              <p>１．すぐに変更</p>
              <p>現在の会員サービスをすぐに消去し、新しい会員ランクに変更します。残りの使用期間はすべて無効になりますので、ご注意ください。新しい会員ランクで毎月自動契約を継続いたします。</p>
            </li>
            <li class="list-item">
              <p>２．サービス期間終了後自動変更</p>
              <p>現在の会員サービス期間満了後、新しい会員ランクを自動に変更いたします。変更後、新しい会員ランクで毎月自動契約を継続いたします。</p>
            </li>
          </ul>
        </div>
      </van-popup>

      <!-- 取消订阅弹窗开始 -->
      <van-popup
        position="bottom"
        class="popup popup-unsubscribe"
        closeable
        v-model="visible4"
      >
        <div class="content">
          <div class="popup-title">サブスクをキャンセル</div>
          <ul class="list">
            <li
              class="list-item"
              :class="{active: unsubscribeInfo.selected.includes(item)}"
              @click="onUnsubscribeSelect(item)"
              v-for="(item, index) in unsubscribeList"
              :key="index"
            >{{item}}</li>
          </ul>
          <textarea v-if="unsubscribeInfo.isOther" v-model="unsubscribeInfo.content" class="textarea" placeholder="キャンセルの原因を教えてください。"></textarea>
          <button
            v-if="unsubscribeInfo.isOther&&!unsubscribeInfo.content || unsubscribeInfo.selected.length === 0"
            type="button"
            class="submit-btn disabled"
            @click="onUnsubscribe"
          >次へ</button>
          <button
            v-else
            type="button"
            @click="onUnsubscribe"
            class="submit-btn"
          >次へ</button>
        </div>
      </van-popup>
      <!-- 取消订阅弹窗结束 -->

      <van-popup
        class="popup popup-coupons"
        v-model="visible5"
      >
        <div class="popup-header">
          <div class="popup-title">クーポンをプレゼント！</div>
          <div class="tip">サブスクを続けて、クーポンを貰えます</div>
        </div>
        <div class="content">

          <ul class="list">
            <li
              class="list-item"
              v-for="(item,index) in [couponsList]"
              :key="index"
            >
              <div class="left">
                <div class="title text-ellipsis">{{ item.name }}</div>
                <div class="tip text-ellipsis">受領後{{ item.day }}日間有効</div>
              </div>
              <div class="right">
                {{ item.price }}
              </div>
            </li>
          </ul>
          <div class="content-footer">
            <button @click="onCoupons('cancel')" class="btn btn-cancel">結構です</button>
            <button @click="onCoupons('confirm',couponsList.id)" class="btn btn-confirm">受領（{{couponsInfo.countDown}}）</button>
          </div>
        </div>
      </van-popup>
    </div>
</template>

<script>
import { NavBar, Checkbox } from 'vant';
import { mapGetters } from 'vuex';

export default {
  name: 'MemberIndex',
  components: {
    'van-nav-bar': NavBar,
    'van-checkbox': Checkbox
  },
  data () {
    return {
      imgObj: {
        0: './static/images/img_vip_01.png',
        1: './static/images/img_vip_02.png',
        2: './static/images/img_vip_03.png',
        3: './static/images/img_vip_04.png'
      },
      selectIndex: '',
      selectedId: 0,
      vipList: [{
        title: 'プレミアム会員',
        desrc: '４K、HDを含むすべての動画を見放題！',
        price: 3450,
        marketPrice: 4450,
        id: 1
      }, {
        title: 'スタンダード会員',
        desrc: 'HDとSD動画を見放題！',
        price: 1980,
        marketPrice: 2980,
        id: 2
      }, {
        title: 'ベーシク会員',
        desrc: 'SD動画を見放題！',
        price: 1490,
        marketPrice: 2490,
        id: 3
      }],
      selected: null,
      unsubscribeList: ['体感が合わない', '使いにくい', '値段が高い', '画質選択肢が少ない', '動画がスムーズではない', 'その他'],
      unsubscribeInfo: {
        selected: [],
        isOther: false,
        content: ''
      },
      couponsInfo: {
        countDown: 10,
        timer: null
      },
      agreementChecked: false,
      visible1: false, // 绑定支付账号
      visible2: false, // 变更选择
      visible3: false, // 变更须知
      visible4: false, // 取消订阅
      visible5: false, // 领取优惠券
      userInfo: {},

      couponsList: {}, // 取消订阅优惠券列表
      timer: null,
      isBind: 0, // 0 没有绑定 1 绑定
      isSubmit: true
    };
  },
  created () {
    this.getDetail();
  },
  mounted () {
    this.isBind = this.personInfo.crediCardState;
  },
  computed: {
    ...mapGetters(['personInfo']),
    getLift () {
      let { $utils, userInfo: { list, jurisdiction } } = this;

      /* 有登陆情况下 如果有重复则去重 没有则显示3个礼包列表 */
      if ($utils.getToken()) {
        if (list) {
          for (let item of list) {
            if (jurisdiction && jurisdiction.length === item.interestsName.length && jurisdiction.every((Juitem, index) => item.interestsName.includes(Juitem))) {
              list = list.filter((LiItem) => LiItem !== item);
            }
          }
        }
      } else {
        list = this.userInfo.list;
      }
      return list || [];
    }
  },
  watch: {},
  methods: {

    /* 获取展示礼包列表数据信息 */
    getDetail () {
      this.$http.get('mine/activityList').then((res) => {
        let { userInfo } = this;
        userInfo = res.data || {};
        this.userInfo = userInfo;
      });
    },

    /* 购买会员礼包 */
    onSubmit () {
      const { $dialog, agreementChecked, vipList, selectIndex, selected, $utils, $router } = this;
      if ($utils.verifyEmpty(selectIndex !== '', '会員プレゼントを選んでください。') && $utils.verifyEmpty(agreementChecked, 'サブスクリプション規約とプライバシーポリシーに同意は必要です')) {
        const router = {
          name: 'MemberOrderConfirm',
          query: {
            id: this.selectedId,
            type: ''
          }
        };
        if (this.isSubmit) {
          this.isSubmit = false;
          console.log(1);

          /* 选择变更 */
          if (selected) {
            $dialog.confirm({
              title: 'ご注意',
              message: `今は${selected.title}ですが、${vipList[selectIndex].title}に変更しますか？`,
              confirmButtonText: '続く',
              cancelButtonText: 'キャンセル',
              closeOnClickOverlay: true
            }).then(() => {
              if (this.isBind === 0) {
                this.bindPay({
                  success: () => {
                    this.timeSuccess(router);
                  }
                });
              } else if (this.isBind === 2) {
                $utils.toast({
                  message: 'お客様のクレジットカードが期限切れになりました。クレジットカードの情報を更新してください。',
                  onClose: () => {
                    this.$router.push({ name: 'SettingIndex', params: { type: 1 } });
                  }
                });
              } else {
                $router.push(router);
              }
            }).catch(() => {});
          } else {
          // 不是选择变更
            if (this.isBind === 0) {
              this.bindPay({
                success: () => {
                  this.timeSuccess(router);
                }
              });
            } else if (this.isBind === 2) {
              $utils.toast({
                message: 'お客様のクレジットカードが期限切れになりました。クレジットカードの情報を更新してください。',
                onClose: () => {
                  this.$router.push({ name: 'SettingIndex', params: { type: 1 } });
                }
              });
            } else {
              $router.push(router);
            }
          }
        }

      }
    },

    /* 绑定信用卡 */
    bindPay (obj) {
      this.$http.get('set/changeOrBindingCard', { showLoading: true }).then(async (res) => {
        const elepay = await this.loadElepay('pk_live_90f822a7a681e4670c3d3');
        elepay.handleSource(res.data).then((result) => {
          // ① 正常処理
          if (result.type === 'cancel') {
            console.log('取消支付');
          } else if (result.type === 'success') {
            obj.success();
          }
        }).catch((err) => {
          console.log(err);
        });
      });
    },

    /* 会员变更 */
    onChange () {
      const { agreementChecked, selectIndex, $utils } = this;
      if ($utils.verifyEmpty(selectIndex !== '', '会員プレゼントを選んでください。') && $utils.verifyEmpty(agreementChecked, 'サブスクリプション規約とプライバシーポリシーに同意は必要です')) {
        this.visible2 = true;
      }
    },

    /* 时间轮询 */
    timeSuccess (router) {
      let timer;
      timer = setTimeout(() => {
        if (this.isBind === 0) {
          this.$http.get('mine/information').then((res) => {
            this.isBind = res.data.crediCardState;
          });
          this.timeSuccess();
        } else {
          clearTimeout(timer);
          this.$router.push({
            name: 'MemberOrderConfirm',
            query: {
              id: this.selectedId,
              type: ''
            }
          });
        }
      }, 1000);
    },

    /* 变更选择 */
    now (type) {
      const { $router } = this;
      $router.push({
        name: 'MemberOrderConfirm',
        query: {
          id: this.selectedId,
          type
        }
      });
    },

    /* 点击切换礼包卡片时 */
    onSelect (item, id) {
      const { selectIndex } = this;
      this.selectIndex = selectIndex === item ? '' : item;
      this.selectedId = id;
    },

    onUnsubscribe () {

      if (this.unsubscribeInfo.isOther) {
        if (this.unsubscribeInfo.content === '') {
          this.$utils.failToast('キャンセルの理由を記入してください。');
          return;
        }
      }

      this.$http.get('mine/whetherCancel').then((res) => {
        // console.log(res.data);
        let { couponsInfo, popClose, popOpen, $utils, couponsList, unsubscribeInfo: { selected, content } } = this;
        if (res.data.whether) {
          this.$http.get('discount/new/discount', {
            params: {
              type: 3
            }
          }).then((res) => {
            couponsList = res.data;
            console.log(couponsList);
            this.couponsList = couponsList;
          });

          popClose('visible4');
          popOpen('visible5');
          console.log(this.unsubscribeInfo.content);
          clearInterval(couponsInfo.timer);
          couponsInfo.countDown = 10;
          couponsInfo.timer = setInterval(() => {
            if (couponsInfo.countDown > 0) {
              couponsInfo.countDown--;
            } else {
              clearInterval(couponsInfo.timer);
            }
          }, 1000);
        } else {
          let text = '';
          if (selected.includes('その他')) {
            text = content;
          } else {
            text = selected.join(',');
          }
          this.$http.post('mine/unsubscribe', {
            text
          }).then((res) => {
            $utils.successToast('購読をキャンセルしました');
            popClose('visible4');
            this.getDetail();
          });
        }
      });
    },

    /* 取消订阅第一步 */
    onUnsubscribeSelect (item) {
      const { unsubscribeInfo: { selected } } = this;
      const index = selected.indexOf(item);
      this.unsubscribeInfo.isOther = false;

      // if (index > -1) {
      //   selected.splice(index, 1);
      // } else if (item === 'その他') {
      //   this.unsubscribeInfo.selected = [item];
      //   this.unsubscribeInfo.isOther = true;
      // } else {
      //   if (selected.includes('その他')) {
      //     selected.shift();
      //   }
      //   selected.push(item);
      // }
      if (index > -1) {
        selected.splice(index, 1);
      } else if (item === 'その他') {
        // 清空所有内容
        selected.splice(0, selected.length);
        selected.push('その他');
        this.unsubscribeInfo.isOther = true;
      } else {
        if (selected.includes('その他')) {
          selected.shift();
        }
        selected.push(item);
      }
    },

    /* 正式取消订阅 */
    onCoupons (type, id) {
      const { unsubscribeInfo: { selected, content }, popClose, $utils, $router } = this;
      let text = '';
      switch (type) {
        case 'cancel':
          if (selected.includes('その他')) {
            text = content;
          } else {
            text = selected.join(',');
          }
          this.$http.post('mine/unsubscribe', {
            text
          }).then((res) => {
            $utils.successToast('購読をキャンセルしました');
            this.getDetail();
          });
          break;
        case 'confirm':
          this.$http.post('discount/drawDiscount', { id }).then((res) => {
            $utils.successToast({
              message: '受領成功！',
              onClose: () => {
                $router.push({
                  name: 'Category'
                });
              }
            });
          });
          break;
      }
      popClose('visible5');
    },
    popClose (name) {
      this[name] = false;
    },
    popOpen (name) {
      this[name] = true;
    }
  }
};
</script>

<style scoped lang="less">
  #memberIndexPage {
    width: 750px;
    margin: auto;

    .top-nav-bar {
      .right {
        .btn {
          height: 48px;
          padding-right: 8px;
          padding-left: 8px;
          font-size: 24px;
          color: #2d2e2e;
          background-color: @primaryBlueBg;
          border-radius: 8px;
        }
      }
    }

    .header {
      position: relative;

      .bg {
        width: 100%;
      }

      .header-content {
        position: absolute;
        top: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        font-weight: bold;
        color: #f4d8c5;
        transform: translateY(-50%);

        .title {
          margin-bottom: 30px;
          font-size: 60px;
          line-height: 74px;
        }

        .tip {
          display: flex;
          align-items: center;
          padding-right: 14px;
          padding-left: 14px;

          .name {
            padding-top: 6px;
            padding-bottom: 6px;
            margin-right: 8px;
            margin-left: 8px;
            font-size: 24px;
            line-height: 30px;
            background-image: linear-gradient(90deg, rgba(72, 63, 62, 0.09) 0%, rgba(96, 91, 90, 0.65) 53%, rgba(128, 128, 128, 0) 100%);
          }

          &::after,
          &::before {
            display: block;
            flex-shrink: 0;
            width: 12px;
            height: 12px;
            content: '';
            background-color: #f4d8c5;
            transform: rotateZ(45deg);
          }
        }
      }
    }

    .container {
      .section {
        background-color: #32303a;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .section-title {
          display: flex;
          flex-direction: column;
          align-items: center;
          // padding-top: 54px;
          padding-bottom: 54px;
          font-weight: bold;
          color: #f4d8c5;

          .img {
            margin-bottom: 30px;
          }

          .title {
            display: flex;
            align-items: center;
            padding-right: 14px;
            padding-left: 14px;

            .name {
              margin-right: 20px;
              margin-left: 20px;
              font-size: 30px;
              line-height: 44px;
            }

            &::after,
            &::before {
              display: block;
              flex-shrink: 0;
              width: 56px;
              height: 2px;
              content: '';
            }

            &::before {
              background-image: linear-gradient(90deg, rgba(244, 216, 197, 0) 0%, rgba(244, 216, 197, 0.58) 100%);
            }

            &::after {
              background-image: linear-gradient(-90deg, rgba(244, 216, 197, 0) 0%, rgba(244, 216, 197, 0.58) 100%);
            }
          }

          .tip {
            font-size: 20px;
            line-height: 32px;

            &:not(:first-child) {
              margin-top: 24px;
            }
          }
        }

        &.section-2 {
          .section-content {
            padding: 6px 34px 58px;

            .list {
              .list-item {
                display: flex;
                min-height: 266px;
                overflow: hidden;
                cursor: pointer;
                border-radius: 16px;

                &:not(:last-child) {
                  margin-bottom: 20px;
                }

                .left {
                  display: flex;
                  flex: 1;
                  flex-direction: column;
                  justify-content: space-between;
                  padding: 32px 98px 32px 68px;
                  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

                  .top {
                    .title {
                      position: relative;
                      margin-bottom: 20px;
                      font-size: 28px;
                      font-weight: bold;
                      line-height: 34px;

                      &::before {
                        position: absolute;
                        top: 50%;
                        right: 100%;
                        width: 30px;
                        height: 26px;
                        margin-right: 8px;
                        content: '';
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: contain;
                        transform: translateY(-50%);
                      }
                    }

                    .desrc {
                      font-size: 26px;
                      line-height: 34px;
                      opacity: 0.7;
                    }
                  }

                  .bottom {
                    margin-top: 20px;
                    font-size: 24px;

                    .price {
                      margin-right: 12px;
                      font-weight: bold;
                      color: #32303a;

                      .big {
                        font-size: 36px;
                      }
                    }
                  }
                }

                .right {
                  position: relative;
                  width: 222px;
                  background-repeat: no-repeat;
                  background-position: right bottom;
                  background-size: contain;

                  .icon-checked {
                    position: absolute;
                    right: 16px;
                    bottom: 12px;
                    width: 42px;
                    height: 42px;
                    background-color: #fff;
                    border-radius: 50%;
                  }
                }

                &.list-item-1 {
                  background-image: linear-gradient(180deg, #a1b6c1 0%, #8f9f93 100%);

                  .left {
                    .top {
                      .title {
                        &::before {
                          background-image: url("~@/assets/images/icon_vip_12.png");
                        }
                      }
                    }
                  }

                  .right {
                    background-image: url("~@/assets/images/icon_vip_09.png");
                  }
                }

                &.list-item-2 {
                  background-image: linear-gradient(180deg, #e8ca64 0%, #e9cf77 100%);

                  .left {
                    .top {
                      .title {
                        &::before {
                          background-image: url("~@/assets/images/icon_vip_11.png");
                        }
                      }
                    }
                  }

                  .right {
                    background-image: url("~@/assets/images/icon_vip_08.png");
                  }
                }

                &.list-item-3 {
                  background-image: linear-gradient(180deg, #41c5d2 0%, #16a8df 100%);

                  .left {
                    .top {
                      .title {
                        &::before {
                          background-image: url("~@/assets/images/icon_vip_10.png");
                        }
                      }
                    }
                  }

                  .right {
                    background-image: url("~@/assets/images/icon_vip_07.png");
                  }
                }

                &.active {
                  .right {
                    .icon-checked {
                      background-image: url("~@/assets/images/icon_checkbox_02.png");
                    }
                  }
                }
              }
            }
          }

          .section-bottom {
            padding-bottom: 30px;
            margin-top: -24px;
            font-size: 28px;
            line-height: 40px;
            color: @primaryBlueColor;
            text-align: center;
            text-decoration: underline;
            opacity: 0.7;
          }
        }
      }
    }

    .footer {
      padding: 48px 30px 98px;

      .tip-box {
        padding: 20px;
        margin-right: -4px;
        margin-bottom: 46px;
        margin-left: -4px;
        font-size: 28px;
        line-height: 38px;
        text-align: center;
        background-color: rgba(64, 64, 64, 0.7);
        border-radius: 16px;
      }

      .nav {
        display: flex;
        align-items: center;
        height: 28px;
        margin-bottom: 18px;
        font-size: 20px;
        line-height: 28px;

        /deep/
        .van-checkbox {
          .van-checkbox__icon {
            font-size: 24px;

            .van-icon {
              border-radius: 0;
            }
          }
        }

        .name {
          margin-right: 10px;
          margin-left: 10px;
          color: @primaryBlueColor;
          text-decoration: underline;
          opacity: 0.7;
        }
      }
    }

    .popup-bindPay {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 672px;
      height: 83%;
      max-height: 1118px;

      .content {
        width: 360px;
        font-size: 30px;
        font-weight: bold;
        line-height: 86px;
        text-align: center;
      }
    }

    .popup-changeType {
      .content {
        padding: 28px;

        & > :not(:last-child) {
          margin-bottom: 42px;
        }

        .popup-title {
          padding-top: 12px;
        }

        .list {
          .list-item {
            padding-top: 40px;
            padding-bottom: 40px;
            font-size: 26px;
            line-height: 34px;
            cursor: pointer;
            border-bottom: 1px solid rgba(255, 255, 255, 0.05);
          }
        }

        .tip {
          text-align: center;

          .name {
            font-size: 28px;
            line-height: 40px;
            color: #36abf5;
            text-decoration: underline;
            cursor: pointer;
            opacity: 0.7;
          }
        }

        .submit-btn {
          font-size: 26px;
          color: rgba(255, 255, 255, 0.8);
          background: @primaryBlackBg;
        }
      }
    }

    .popup-changeTip {
      width: 682px;
      padding: 36px 40px 112px;

      .list {
        .list-item {
          margin-top: 56px;
          font-size: 24px;
          line-height: 40px;
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }

    .popup-unsubscribe {
      .content {
        padding: 28px;

        .popup-title {
          padding-top: 20px;
          margin-bottom: 10px;
          text-align: left;
        }

        .list {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          .list-item {
            width: 214px;
            padding: 14px 10px;
            margin-top: 42px;
            font-size: 24px;
            line-height: 32px;
            color: rgba(255, 255, 255, 0.6);
            text-align: center;
            background-color: rgba(255, 255, 255, 0.04);
            border-radius: 40px;

            &:not(:nth-of-type(3n)) {
              margin-right: 26px;
            }

            &.active {
              color: #2d2e2e;
              background: linear-gradient(180deg, #36abf5 0%, #7acbff 100%);
            }
          }
        }

        .textarea {
          height: 136px;
          margin-top: 28px;
          font-size: 24px;
        }

        .submit-btn {
          margin-top: 68px;

          &.disabled {
            font-size: 26px;
            color: rgba(255, 255, 255, 0.8);
            background: @primaryBlackBg;
          }
        }
      }
    }

    .popup-coupons {
      width: 670px;
      overflow: hidden;
      border-radius: 54px 54px 0 0;

      .popup-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 162px;
        background-image: linear-gradient(180deg, #ff804a 0%, #ff6059 100%);

        .popup-title {
          margin-bottom: 16px;
          font-size: 36px;
          line-height: 46px;
        }

        .tip {
          font-size: 24px;
          line-height: 32px;
          opacity: 0.7;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        // height: 700px;
        padding: 48px 36px 64px;
        margin-top: -1px;
        background-image: linear-gradient(180deg, #ff804a 0%, #ff6059 100%);

        .list {
          height: 100%;
          overflow-y: auto;

          .list-item {
            display: flex;
            align-items: center;
            height: 142px;
            padding-top: 16px;
            padding-bottom: 16px;
            background-color: #fff;
            border-radius: 16px;

            &:not(:last-child) {
              margin-bottom: 20px;
            }

            .left {
              flex: 1;
              align-self: stretch;
              padding: 16px 24px;
              border-right: 2px dashed #ccc;

              .title {
                margin-bottom: 10px;
                font-size: 36px;
                font-weight: bold;
                line-height: 46px;
                color: #333;
              }

              .tip {
                font-size: 24px;
                line-height: 32px;
                color: #666;
              }
            }

            .right {
              flex-shrink: 0;
              width: 172px;
              padding-right: 8px;
              padding-left: 8px;
              font-size: 52px;
              font-weight: bold;
              color: #ee3d19;
              text-align: center;
            }
          }
        }

        .content-footer {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          margin-top: 40px;

          .btn {
            height: 82px;
            border-radius: 16px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);

            &.btn-cancel {
              width: 212px;
              margin-right: 20px;
              font-size: 26px;
              color: rgba(255, 255, 255, 0.8);
              background-color: #ff6854;
              border-radius: 16px;
            }

            &.btn-confirm {
              flex: 1;
              font-size: 32px;
              font-weight: bold;
              color: #333;
              background-color: #fac26c;
            }
          }
        }
      }
    }
  }
</style>